<template>
  <div class="position-relative">
    <div>
      <button
        class="btn btn-light d-flex align-items-center"
        @click="showSelector = !showSelector"
      >
        <span class="btn-swatch" :class="'bg-' + value"></span
        ><span class="me-1">{{ !showSelector ? "Show" : "Hide" }}</span
        >Color Selector
      </button>
    </div>

    <!--  -->
    <div class="color-select-window" v-show="showSelector">
      <div class="container">
        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              transparent
            "
            @click="selectSwatch('transparent')"
            :class="value == 'transparent' ? 'color-active' : ''"
          >
            <span class="swatch bg-transparent"></span>
            <span class="title">transparent</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              black
            "
            @click="selectSwatch('black')"
            :class="value == 'black' ? 'color-active' : ''"
          >
            <span class="swatch bg-black"></span>
            <span class="title">black</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              white
            "
            @click="selectSwatch('white')"
            :class="value == 'white' ? 'color-active' : ''"
          >
            <span class="swatch bg-white"></span>
            <span class="title">white</span>
          </div>
        </div>
        <!--  -->
        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              slate-50
            "
            @click="selectSwatch('slate-50')"
            :class="value == 'slate-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-slate-50"></span>
            <span class="title">slate-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              slate-100
            "
            @click="selectSwatch('slate-100')"
            :class="value == 'slate-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-slate-100"></span>
            <span class="title">slate-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              slate-200
            "
            @click="selectSwatch('slate-200')"
            :class="value == 'slate-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-slate-200"></span>
            <span class="title">slate-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              slate-300
            "
            @click="selectSwatch('slate-300')"
            :class="value == 'slate-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-slate-300"></span>
            <span class="title">slate-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              slate-400
            "
            @click="selectSwatch('slate-400')"
            :class="value == 'slate-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-slate-400"></span>
            <span class="title">slate-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              slate-500
            "
            @click="selectSwatch('slate-500')"
            :class="value == 'slate-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-slate-500"></span>
            <span class="title">slate-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              slate-600
            "
            @click="selectSwatch('slate-600')"
            :class="value == 'slate-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-slate-600"></span>
            <span class="title">slate-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              slate-700
            "
            @click="selectSwatch('slate-700')"
            :class="value == 'slate-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-slate-700"></span>
            <span class="title">slate-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              slate-800
            "
            @click="selectSwatch('slate-800')"
            :class="value == 'slate-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-slate-800"></span>
            <span class="title">slate-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              slate-900
            "
            @click="selectSwatch('slate-900')"
            :class="value == 'slate-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-slate-900"></span>
            <span class="title">slate-900</span>
          </div>
        </div>

        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              gray-50
            "
            @click="selectSwatch('gray-50')"
            :class="value == 'gray-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-gray-50"></span>
            <span class="title">gray-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              gray-100
            "
            @click="selectSwatch('gray-100')"
            :class="value == 'gray-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-gray-100"></span>
            <span class="title">gray-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              gray-200
            "
            @click="selectSwatch('gray-200')"
            :class="value == 'gray-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-gray-200"></span>
            <span class="title">gray-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              gray-300
            "
            @click="selectSwatch('gray-300')"
            :class="value == 'gray-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-gray-300"></span>
            <span class="title">gray-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              gray-400
            "
            @click="selectSwatch('gray-400')"
            :class="value == 'gray-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-gray-400"></span>
            <span class="title">gray-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              gray-500
            "
            @click="selectSwatch('gray-500')"
            :class="value == 'gray-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-gray-500"></span>
            <span class="title">gray-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              gray-600
            "
            @click="selectSwatch('gray-600')"
            :class="value == 'gray-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-gray-600"></span>
            <span class="title">gray-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              gray-700
            "
            @click="selectSwatch('gray-700')"
            :class="value == 'gray-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-gray-700"></span>
            <span class="title">gray-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              gray-800
            "
            @click="selectSwatch('gray-800')"
            :class="value == 'gray-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-gray-800"></span>
            <span class="title">gray-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              gray-900
            "
            @click="selectSwatch('gray-900')"
            :class="value == 'gray-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-gray-900"></span>
            <span class="title">gray-900</span>
          </div>
        </div>

        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              zinc-50
            "
            @click="selectSwatch('zinc-50')"
            :class="value == 'zinc-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-zinc-50"></span>
            <span class="title">zinc-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              zinc-100
            "
            @click="selectSwatch('zinc-100')"
            :class="value == 'zinc-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-zinc-100"></span>
            <span class="title">zinc-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              zinc-200
            "
            @click="selectSwatch('zinc-200')"
            :class="value == 'zinc-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-zinc-200"></span>
            <span class="title">zinc-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              zinc-300
            "
            @click="selectSwatch('zinc-300')"
            :class="value == 'zinc-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-zinc-300"></span>
            <span class="title">zinc-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              zinc-400
            "
            @click="selectSwatch('zinc-400')"
            :class="value == 'zinc-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-zinc-400"></span>
            <span class="title">zinc-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              zinc-500
            "
            @click="selectSwatch('zinc-500')"
            :class="value == 'zinc-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-zinc-500"></span>
            <span class="title">zinc-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              zinc-600
            "
            @click="selectSwatch('zinc-600')"
            :class="value == 'zinc-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-zinc-600"></span>
            <span class="title">zinc-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              zinc-700
            "
            @click="selectSwatch('zinc-700')"
            :class="value == 'zinc-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-zinc-700"></span>
            <span class="title">zinc-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              zinc-800
            "
            @click="selectSwatch('zinc-800')"
            :class="value == 'zinc-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-zinc-800"></span>
            <span class="title">zinc-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              zinc-900
            "
            @click="selectSwatch('zinc-900')"
            :class="value == 'zinc-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-zinc-900"></span>
            <span class="title">zinc-900</span>
          </div>
        </div>
        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              neutral-50
            "
            @click="selectSwatch('neutral-50')"
            :class="value == 'neutral-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-neutral-50"></span>
            <span class="title">neutral-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              neutral-100
            "
            @click="selectSwatch('neutral-100')"
            :class="value == 'neutral-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-neutral-100"></span>
            <span class="title">neutral-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              neutral-200
            "
            @click="selectSwatch('neutral-200')"
            :class="value == 'neutral-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-neutral-200"></span>
            <span class="title">neutral-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              neutral-300
            "
            @click="selectSwatch('neutral-300')"
            :class="value == 'neutral-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-neutral-300"></span>
            <span class="title">neutral-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              neutral-400
            "
            @click="selectSwatch('neutral-400')"
            :class="value == 'neutral-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-neutral-400"></span>
            <span class="title">neutral-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              neutral-500
            "
            @click="selectSwatch('neutral-500')"
            :class="value == 'neutral-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-neutral-500"></span>
            <span class="title">neutral-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              neutral-600
            "
            @click="selectSwatch('neutral-600')"
            :class="value == 'neutral-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-neutral-600"></span>
            <span class="title">neutral-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              neutral-700
            "
            @click="selectSwatch('neutral-700')"
            :class="value == 'neutral-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-neutral-700"></span>
            <span class="title">neutral-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              neutral-800
            "
            @click="selectSwatch('neutral-800')"
            :class="value == 'neutral-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-neutral-800"></span>
            <span class="title">neutral-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              neutral-900
            "
            @click="selectSwatch('neutral-900')"
            :class="value == 'neutral-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-neutral-900"></span>
            <span class="title">neutral-900</span>
          </div>
        </div>

        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              stone-50
            "
            @click="selectSwatch('stone-50')"
            :class="value == 'stone-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-stone-50"></span>
            <span class="title">stone-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              stone-100
            "
            @click="selectSwatch('stone-100')"
            :class="value == 'stone-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-stone-100"></span>
            <span class="title">stone-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              stone-200
            "
            @click="selectSwatch('stone-200')"
            :class="value == 'stone-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-stone-200"></span>
            <span class="title">stone-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              stone-300
            "
            @click="selectSwatch('stone-300')"
            :class="value == 'stone-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-stone-300"></span>
            <span class="title">stone-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              stone-400
            "
            @click="selectSwatch('stone-400')"
            :class="value == 'stone-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-stone-400"></span>
            <span class="title">stone-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              stone-500
            "
            @click="selectSwatch('stone-500')"
            :class="value == 'stone-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-stone-500"></span>
            <span class="title">stone-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              stone-600
            "
            @click="selectSwatch('stone-600')"
            :class="value == 'stone-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-stone-600"></span>
            <span class="title">stone-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              stone-700
            "
            @click="selectSwatch('stone-700')"
            :class="value == 'stone-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-stone-700"></span>
            <span class="title">stone-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              stone-800
            "
            @click="selectSwatch('stone-800')"
            :class="value == 'stone-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-stone-800"></span>
            <span class="title">stone-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              stone-900
            "
            @click="selectSwatch('stone-900')"
            :class="value == 'stone-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-stone-900"></span>
            <span class="title">stone-900</span>
          </div>
        </div>

        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              red-50
            "
            @click="selectSwatch('red-50')"
            :class="value == 'red-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-red-50"></span>
            <span class="title">red-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              red-100
            "
            @click="selectSwatch('red-100')"
            :class="value == 'red-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-red-100"></span>
            <span class="title">red-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              red-200
            "
            @click="selectSwatch('red-200')"
            :class="value == 'red-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-red-200"></span>
            <span class="title">red-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              red-300
            "
            @click="selectSwatch('red-300')"
            :class="value == 'red-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-red-300"></span>
            <span class="title">red-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              red-400
            "
            @click="selectSwatch('red-400')"
            :class="value == 'red-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-red-400"></span>
            <span class="title">red-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              red-500
            "
            @click="selectSwatch('red-500')"
            :class="value == 'red-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-red-500"></span>
            <span class="title">red-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              red-600
            "
            @click="selectSwatch('red-600')"
            :class="value == 'red-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-red-600"></span>
            <span class="title">red-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              red-700
            "
            @click="selectSwatch('red-700')"
            :class="value == 'red-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-red-700"></span>
            <span class="title">red-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              red-800
            "
            @click="selectSwatch('red-800')"
            :class="value == 'red-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-red-800"></span>
            <span class="title">red-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              red-900
            "
            @click="selectSwatch('red-900')"
            :class="value == 'red-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-red-900"></span>
            <span class="title">red-900</span>
          </div>
        </div>

        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              orange-50
            "
            @click="selectSwatch('orange-50')"
            :class="value == 'orange-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-orange-50"></span>
            <span class="title">orange-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              orange-100
            "
            @click="selectSwatch('orange-100')"
            :class="value == 'orange-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-orange-100"></span>
            <span class="title">orange-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              orange-200
            "
            @click="selectSwatch('orange-200')"
            :class="value == 'orange-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-orange-200"></span>
            <span class="title">orange-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              orange-300
            "
            @click="selectSwatch('orange-300')"
            :class="value == 'orange-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-orange-300"></span>
            <span class="title">orange-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              orange-400
            "
            @click="selectSwatch('orange-400')"
            :class="value == 'orange-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-orange-400"></span>
            <span class="title">orange-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              orange-500
            "
            @click="selectSwatch('orange-500')"
            :class="value == 'orange-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-orange-500"></span>
            <span class="title">orange-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              orange-600
            "
            @click="selectSwatch('orange-600')"
            :class="value == 'orange-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-orange-600"></span>
            <span class="title">orange-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              orange-700
            "
            @click="selectSwatch('orange-700')"
            :class="value == 'orange-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-orange-700"></span>
            <span class="title">orange-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              orange-800
            "
            @click="selectSwatch('orange-800')"
            :class="value == 'orange-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-orange-800"></span>
            <span class="title">orange-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              orange-900
            "
            @click="selectSwatch('orange-900')"
            :class="value == 'orange-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-orange-900"></span>
            <span class="title">orange-900</span>
          </div>
        </div>

        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              amber-50
            "
            @click="selectSwatch('amber-50')"
            :class="value == 'amber-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-amber-50"></span>
            <span class="title">amber-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              amber-100
            "
            @click="selectSwatch('amber-100')"
            :class="value == 'amber-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-amber-100"></span>
            <span class="title">amber-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              amber-200
            "
            @click="selectSwatch('amber-200')"
            :class="value == 'amber-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-amber-200"></span>
            <span class="title">amber-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              amber-300
            "
            @click="selectSwatch('amber-300')"
            :class="value == 'amber-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-amber-300"></span>
            <span class="title">amber-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              amber-400
            "
            @click="selectSwatch('amber-400')"
            :class="value == 'amber-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-amber-400"></span>
            <span class="title">amber-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              amber-500
            "
            @click="selectSwatch('amber-500')"
            :class="value == 'amber-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-amber-500"></span>
            <span class="title">amber-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              amber-600
            "
            @click="selectSwatch('amber-600')"
            :class="value == 'amber-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-amber-600"></span>
            <span class="title">amber-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              amber-700
            "
            @click="selectSwatch('amber-700')"
            :class="value == 'amber-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-amber-700"></span>
            <span class="title">amber-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              amber-800
            "
            @click="selectSwatch('amber-800')"
            :class="value == 'amber-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-amber-800"></span>
            <span class="title">amber-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              amber-900
            "
            @click="selectSwatch('amber-900')"
            :class="value == 'amber-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-amber-900"></span>
            <span class="title">amber-900</span>
          </div>
        </div>

        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              yellow-50
            "
            @click="selectSwatch('yellow-50')"
            :class="value == 'yellow-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-yellow-50"></span>
            <span class="title">yellow-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              yellow-100
            "
            @click="selectSwatch('yellow-100')"
            :class="value == 'yellow-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-yellow-100"></span>
            <span class="title">yellow-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              yellow-200
            "
            @click="selectSwatch('yellow-200')"
            :class="value == 'yellow-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-yellow-200"></span>
            <span class="title">yellow-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              yellow-300
            "
            @click="selectSwatch('yellow-300')"
            :class="value == 'yellow-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-yellow-300"></span>
            <span class="title">yellow-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              yellow-400
            "
            @click="selectSwatch('yellow-400')"
            :class="value == 'yellow-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-yellow-400"></span>
            <span class="title">yellow-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              yellow-500
            "
            @click="selectSwatch('yellow-500')"
            :class="value == 'yellow-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-yellow-500"></span>
            <span class="title">yellow-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              yellow-600
            "
            @click="selectSwatch('yellow-600')"
            :class="value == 'yellow-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-yellow-600"></span>
            <span class="title">yellow-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              yellow-700
            "
            @click="selectSwatch('yellow-700')"
            :class="value == 'yellow-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-yellow-700"></span>
            <span class="title">yellow-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              yellow-800
            "
            @click="selectSwatch('yellow-800')"
            :class="value == 'yellow-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-yellow-800"></span>
            <span class="title">yellow-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              yellow-900
            "
            @click="selectSwatch('yellow-900')"
            :class="value == 'yellow-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-yellow-900"></span>
            <span class="title">yellow-900</span>
          </div>
        </div>
        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              lime-50
            "
            @click="selectSwatch('lime-50')"
            :class="value == 'lime-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-lime-50"></span>
            <span class="title">lime-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              lime-100
            "
            @click="selectSwatch('lime-100')"
            :class="value == 'lime-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-lime-100"></span>
            <span class="title">lime-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              lime-200
            "
            @click="selectSwatch('lime-200')"
            :class="value == 'lime-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-lime-200"></span>
            <span class="title">lime-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              lime-300
            "
            @click="selectSwatch('lime-300')"
            :class="value == 'lime-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-lime-300"></span>
            <span class="title">lime-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              lime-400
            "
            @click="selectSwatch('lime-400')"
            :class="value == 'lime-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-lime-400"></span>
            <span class="title">lime-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              lime-500
            "
            @click="selectSwatch('lime-500')"
            :class="value == 'lime-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-lime-500"></span>
            <span class="title">lime-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              lime-600
            "
            @click="selectSwatch('lime-600')"
            :class="value == 'lime-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-lime-600"></span>
            <span class="title">lime-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              lime-700
            "
            @click="selectSwatch('lime-700')"
            :class="value == 'lime-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-lime-700"></span>
            <span class="title">lime-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              lime-800
            "
            @click="selectSwatch('lime-800')"
            :class="value == 'lime-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-lime-800"></span>
            <span class="title">lime-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              lime-900
            "
            @click="selectSwatch('lime-900')"
            :class="value == 'lime-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-lime-900"></span>
            <span class="title">lime-900</span>
          </div>
        </div>

        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              green-50
            "
            @click="selectSwatch('green-50')"
            :class="value == 'green-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-green-50"></span>
            <span class="title">green-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              green-100
            "
            @click="selectSwatch('green-100')"
            :class="value == 'green-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-green-100"></span>
            <span class="title">green-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              green-200
            "
            @click="selectSwatch('green-200')"
            :class="value == 'green-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-green-200"></span>
            <span class="title">green-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              green-300
            "
            @click="selectSwatch('green-300')"
            :class="value == 'green-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-green-300"></span>
            <span class="title">green-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              green-400
            "
            @click="selectSwatch('green-400')"
            :class="value == 'green-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-green-400"></span>
            <span class="title">green-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              green-500
            "
            @click="selectSwatch('green-500')"
            :class="value == 'green-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-green-500"></span>
            <span class="title">green-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              green-600
            "
            @click="selectSwatch('green-600')"
            :class="value == 'green-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-green-600"></span>
            <span class="title">green-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              green-700
            "
            @click="selectSwatch('green-700')"
            :class="value == 'green-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-green-700"></span>
            <span class="title">green-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              green-800
            "
            @click="selectSwatch('green-800')"
            :class="value == 'green-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-green-800"></span>
            <span class="title">green-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              green-900
            "
            @click="selectSwatch('green-900')"
            :class="value == 'green-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-green-900"></span>
            <span class="title">green-900</span>
          </div>
        </div>

        <!--  -->

        <div class="row">
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              emerald-50
            "
            @click="selectSwatch('emerald-50')"
            :class="value == 'emerald-50' ? 'color-active' : ''"
          >
            <span class="swatch bg-emerald-50"></span>
            <span class="title">emerald-50</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              emerald-100
            "
            @click="selectSwatch('emerald-100')"
            :class="value == 'emerald-100' ? 'color-active' : ''"
          >
            <span class="swatch bg-emerald-100"></span>
            <span class="title">emerald-100</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              emerald-200
            "
            @click="selectSwatch('emerald-200')"
            :class="value == 'emerald-200' ? 'color-active' : ''"
          >
            <span class="swatch bg-emerald-200"></span>
            <span class="title">emerald-200</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              emerald-300
            "
            @click="selectSwatch('emerald-300')"
            :class="value == 'emerald-300' ? 'color-active' : ''"
          >
            <span class="swatch bg-emerald-300"></span>
            <span class="title">emerald-300</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              emerald-400
            "
            @click="selectSwatch('emerald-400')"
            :class="value == 'emerald-400' ? 'color-active' : ''"
          >
            <span class="swatch bg-emerald-400"></span>
            <span class="title">emerald-400</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              emerald-500
            "
            @click="selectSwatch('emerald-500')"
            :class="value == 'emerald-500' ? 'color-active' : ''"
          >
            <span class="swatch bg-emerald-500"></span>
            <span class="title">emerald-500</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              emerald-600
            "
            @click="selectSwatch('emerald-600')"
            :class="value == 'emerald-600' ? 'color-active' : ''"
          >
            <span class="swatch bg-emerald-600"></span>
            <span class="title">emerald-600</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              emerald-700
            "
            @click="selectSwatch('emerald-700')"
            :class="value == 'emerald-700' ? 'color-active' : ''"
          >
            <span class="swatch bg-emerald-700"></span>
            <span class="title">emerald-700</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              emerald-800
            "
            @click="selectSwatch('emerald-800')"
            :class="value == 'emerald-800' ? 'color-active' : ''"
          >
            <span class="swatch bg-emerald-800"></span>
            <span class="title">emerald-800</span>
          </div>
          <div
            class="
              col-auto
              text-center
              mb-4
              swatch-wrapper
              cursor-pointer
              emerald-900
            "
            @click="selectSwatch('emerald-900')"
            :class="value == 'emerald-900' ? 'color-active' : ''"
          >
            <span class="swatch bg-emerald-900"></span>
            <span class="title">emerald-900</span>
          </div>
          <!--  -->
          <div class="row">
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                teal-50
              "
              @click="selectSwatch('teal-50')"
              :class="value == 'teal-50' ? 'color-active' : ''"
            >
              <span class="swatch bg-teal-50"></span>
              <span class="title">teal-50</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                teal-100
              "
              @click="selectSwatch('teal-100')"
              :class="value == 'teal-100' ? 'color-active' : ''"
            >
              <span class="swatch bg-teal-100"></span>
              <span class="title">teal-100</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                teal-200
              "
              @click="selectSwatch('teal-200')"
              :class="value == 'teal-200' ? 'color-active' : ''"
            >
              <span class="swatch bg-teal-200"></span>
              <span class="title">teal-200</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                teal-300
              "
              @click="selectSwatch('teal-300')"
              :class="value == 'teal-300' ? 'color-active' : ''"
            >
              <span class="swatch bg-teal-300"></span>
              <span class="title">teal-300</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                teal-400
              "
              @click="selectSwatch('teal-400')"
              :class="value == 'teal-400' ? 'color-active' : ''"
            >
              <span class="swatch bg-teal-400"></span>
              <span class="title">teal-400</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                teal-500
              "
              @click="selectSwatch('teal-500')"
              :class="value == 'teal-500' ? 'color-active' : ''"
            >
              <span class="swatch bg-teal-500"></span>
              <span class="title">teal-500</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                teal-600
              "
              @click="selectSwatch('teal-600')"
              :class="value == 'teal-600' ? 'color-active' : ''"
            >
              <span class="swatch bg-teal-600"></span>
              <span class="title">teal-600</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                teal-700
              "
              @click="selectSwatch('teal-700')"
              :class="value == 'teal-700' ? 'color-active' : ''"
            >
              <span class="swatch bg-teal-700"></span>
              <span class="title">teal-700</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                teal-800
              "
              @click="selectSwatch('teal-800')"
              :class="value == 'teal-800' ? 'color-active' : ''"
            >
              <span class="swatch bg-teal-800"></span>
              <span class="title">teal-800</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                teal-900
              "
              @click="selectSwatch('teal-900')"
              :class="value == 'teal-900' ? 'color-active' : ''"
            >
              <span class="swatch bg-teal-900"></span>
              <span class="title">teal-900</span>
            </div>
          </div>

          <!--  -->

          <div class="row">
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                cyan-50
              "
              @click="selectSwatch('cyan-50')"
              :class="value == 'cyan-50' ? 'color-active' : ''"
            >
              <span class="swatch bg-cyan-50"></span>
              <span class="title">cyan-50</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                cyan-100
              "
              @click="selectSwatch('cyan-100')"
              :class="value == 'cyan-100' ? 'color-active' : ''"
            >
              <span class="swatch bg-cyan-100"></span>
              <span class="title">cyan-100</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                cyan-200
              "
              @click="selectSwatch('cyan-200')"
              :class="value == 'cyan-200' ? 'color-active' : ''"
            >
              <span class="swatch bg-cyan-200"></span>
              <span class="title">cyan-200</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                cyan-300
              "
              @click="selectSwatch('cyan-300')"
              :class="value == 'cyan-300' ? 'color-active' : ''"
            >
              <span class="swatch bg-cyan-300"></span>
              <span class="title">cyan-300</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                cyan-400
              "
              @click="selectSwatch('cyan-400')"
              :class="value == 'cyan-400' ? 'color-active' : ''"
            >
              <span class="swatch bg-cyan-400"></span>
              <span class="title">cyan-400</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                cyan-500
              "
              @click="selectSwatch('cyan-500')"
              :class="value == 'cyan-500' ? 'color-active' : ''"
            >
              <span class="swatch bg-cyan-500"></span>
              <span class="title">cyan-500</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                cyan-600
              "
              @click="selectSwatch('cyan-600')"
              :class="value == 'cyan-600' ? 'color-active' : ''"
            >
              <span class="swatch bg-cyan-600"></span>
              <span class="title">cyan-600</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                cyan-700
              "
              @click="selectSwatch('cyan-700')"
              :class="value == 'cyan-700' ? 'color-active' : ''"
            >
              <span class="swatch bg-cyan-700"></span>
              <span class="title">cyan-700</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                cyan-800
              "
              @click="selectSwatch('cyan-800')"
              :class="value == 'cyan-800' ? 'color-active' : ''"
            >
              <span class="swatch bg-cyan-800"></span>
              <span class="title">cyan-800</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                cyan-900
              "
              @click="selectSwatch('cyan-900')"
              :class="value == 'cyan-900' ? 'color-active' : ''"
            >
              <span class="swatch bg-cyan-900"></span>
              <span class="title">cyan-900</span>
            </div>
          </div>

          <!--  -->

          <div class="row">
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                sky-50
              "
              @click="selectSwatch('sky-50')"
              :class="value == 'sky-50' ? 'color-active' : ''"
            >
              <span class="swatch bg-sky-50"></span>
              <span class="title">sky-50</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                sky-100
              "
              @click="selectSwatch('sky-100')"
              :class="value == 'sky-100' ? 'color-active' : ''"
            >
              <span class="swatch bg-sky-100"></span>
              <span class="title">sky-100</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                sky-200
              "
              @click="selectSwatch('sky-200')"
              :class="value == 'sky-200' ? 'color-active' : ''"
            >
              <span class="swatch bg-sky-200"></span>
              <span class="title">sky-200</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                sky-300
              "
              @click="selectSwatch('sky-300')"
              :class="value == 'sky-300' ? 'color-active' : ''"
            >
              <span class="swatch bg-sky-300"></span>
              <span class="title">sky-300</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                sky-400
              "
              @click="selectSwatch('sky-400')"
              :class="value == 'sky-400' ? 'color-active' : ''"
            >
              <span class="swatch bg-sky-400"></span>
              <span class="title">sky-400</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                sky-500
              "
              @click="selectSwatch('sky-500')"
              :class="value == 'sky-500' ? 'color-active' : ''"
            >
              <span class="swatch bg-sky-500"></span>
              <span class="title">sky-500</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                sky-600
              "
              @click="selectSwatch('sky-600')"
              :class="value == 'sky-600' ? 'color-active' : ''"
            >
              <span class="swatch bg-sky-600"></span>
              <span class="title">sky-600</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                sky-700
              "
              @click="selectSwatch('sky-700')"
              :class="value == 'sky-700' ? 'color-active' : ''"
            >
              <span class="swatch bg-sky-700"></span>
              <span class="title">sky-700</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                sky-800
              "
              @click="selectSwatch('sky-800')"
              :class="value == 'sky-800' ? 'color-active' : ''"
            >
              <span class="swatch bg-sky-800"></span>
              <span class="title">sky-800</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                sky-900
              "
              @click="selectSwatch('sky-900')"
              :class="value == 'sky-900' ? 'color-active' : ''"
            >
              <span class="swatch bg-sky-900"></span>
              <span class="title">sky-900</span>
            </div>
          </div>

          <!--  -->

          <div class="row">
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                blue-50
              "
              @click="selectSwatch('blue-50')"
              :class="value == 'blue-50' ? 'color-active' : ''"
            >
              <span class="swatch bg-blue-50"></span>
              <span class="title">blue-50</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                blue-100
              "
              @click="selectSwatch('blue-100')"
              :class="value == 'blue-100' ? 'color-active' : ''"
            >
              <span class="swatch bg-blue-100"></span>
              <span class="title">blue-100</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                blue-200
              "
              @click="selectSwatch('blue-200')"
              :class="value == 'blue-200' ? 'color-active' : ''"
            >
              <span class="swatch bg-blue-200"></span>
              <span class="title">blue-200</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                blue-300
              "
              @click="selectSwatch('blue-300')"
              :class="value == 'blue-300' ? 'color-active' : ''"
            >
              <span class="swatch bg-blue-300"></span>
              <span class="title">blue-300</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                blue-400
              "
              @click="selectSwatch('blue-400')"
              :class="value == 'blue-400' ? 'color-active' : ''"
            >
              <span class="swatch bg-blue-400"></span>
              <span class="title">blue-400</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                blue-500
              "
              @click="selectSwatch('blue-500')"
              :class="value == 'blue-500' ? 'color-active' : ''"
            >
              <span class="swatch bg-blue-500"></span>
              <span class="title">blue-500</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                blue-600
              "
              @click="selectSwatch('blue-600')"
              :class="value == 'blue-600' ? 'color-active' : ''"
            >
              <span class="swatch bg-blue-600"></span>
              <span class="title">blue-600</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                blue-700
              "
              @click="selectSwatch('blue-700')"
              :class="value == 'blue-700' ? 'color-active' : ''"
            >
              <span class="swatch bg-blue-700"></span>
              <span class="title">blue-700</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                blue-800
              "
              @click="selectSwatch('blue-800')"
              :class="value == 'blue-800' ? 'color-active' : ''"
            >
              <span class="swatch bg-blue-800"></span>
              <span class="title">blue-800</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                blue-900
              "
              @click="selectSwatch('blue-900')"
              :class="value == 'blue-900' ? 'color-active' : ''"
            >
              <span class="swatch bg-blue-900"></span>
              <span class="title">blue-900</span>
            </div>
          </div>

          <!--  -->

          <div class="row">
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                indigo-50
              "
              @click="selectSwatch('indigo-50')"
              :class="value == 'indigo-50' ? 'color-active' : ''"
            >
              <span class="swatch bg-indigo-50"></span>
              <span class="title">indigo-50</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                indigo-100
              "
              @click="selectSwatch('indigo-100')"
              :class="value == 'indigo-100' ? 'color-active' : ''"
            >
              <span class="swatch bg-indigo-100"></span>
              <span class="title">indigo-100</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                indigo-200
              "
              @click="selectSwatch('indigo-200')"
              :class="value == 'indigo-200' ? 'color-active' : ''"
            >
              <span class="swatch bg-indigo-200"></span>
              <span class="title">indigo-200</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                indigo-300
              "
              @click="selectSwatch('indigo-300')"
              :class="value == 'indigo-300' ? 'color-active' : ''"
            >
              <span class="swatch bg-indigo-300"></span>
              <span class="title">indigo-300</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                indigo-400
              "
              @click="selectSwatch('indigo-400')"
              :class="value == 'indigo-400' ? 'color-active' : ''"
            >
              <span class="swatch bg-indigo-400"></span>
              <span class="title">indigo-400</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                indigo-500
              "
              @click="selectSwatch('indigo-500')"
              :class="value == 'indigo-500' ? 'color-active' : ''"
            >
              <span class="swatch bg-indigo-500"></span>
              <span class="title">indigo-500</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                indigo-600
              "
              @click="selectSwatch('indigo-600')"
              :class="value == 'indigo-600' ? 'color-active' : ''"
            >
              <span class="swatch bg-indigo-600"></span>
              <span class="title">indigo-600</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                indigo-700
              "
              @click="selectSwatch('indigo-700')"
              :class="value == 'indigo-700' ? 'color-active' : ''"
            >
              <span class="swatch bg-indigo-700"></span>
              <span class="title">indigo-700</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                indigo-800
              "
              @click="selectSwatch('indigo-800')"
              :class="value == 'indigo-800' ? 'color-active' : ''"
            >
              <span class="swatch bg-indigo-800"></span>
              <span class="title">indigo-800</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                indigo-900
              "
              @click="selectSwatch('indigo-900')"
              :class="value == 'indigo-900' ? 'color-active' : ''"
            >
              <span class="swatch bg-indigo-900"></span>
              <span class="title">indigo-900</span>
            </div>
          </div>

          <!--  -->

          <div class="row">
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                violet-50
              "
              @click="selectSwatch('violet-50')"
              :class="value == 'violet-50' ? 'color-active' : ''"
            >
              <span class="swatch bg-violet-50"></span>
              <span class="title">violet-50</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                violet-100
              "
              @click="selectSwatch('violet-100')"
              :class="value == 'violet-100' ? 'color-active' : ''"
            >
              <span class="swatch bg-violet-100"></span>
              <span class="title">violet-100</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                violet-200
              "
              @click="selectSwatch('violet-200')"
              :class="value == 'violet-200' ? 'color-active' : ''"
            >
              <span class="swatch bg-violet-200"></span>
              <span class="title">violet-200</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                violet-300
              "
              @click="selectSwatch('violet-300')"
              :class="value == 'violet-300' ? 'color-active' : ''"
            >
              <span class="swatch bg-violet-300"></span>
              <span class="title">violet-300</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                violet-400
              "
              @click="selectSwatch('violet-400')"
              :class="value == 'violet-400' ? 'color-active' : ''"
            >
              <span class="swatch bg-violet-400"></span>
              <span class="title">violet-400</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                violet-500
              "
              @click="selectSwatch('violet-500')"
              :class="value == 'violet-500' ? 'color-active' : ''"
            >
              <span class="swatch bg-violet-500"></span>
              <span class="title">violet-500</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                violet-600
              "
              @click="selectSwatch('violet-600')"
              :class="value == 'violet-600' ? 'color-active' : ''"
            >
              <span class="swatch bg-violet-600"></span>
              <span class="title">violet-600</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                violet-700
              "
              @click="selectSwatch('violet-700')"
              :class="value == 'violet-700' ? 'color-active' : ''"
            >
              <span class="swatch bg-violet-700"></span>
              <span class="title">violet-700</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                violet-800
              "
              @click="selectSwatch('violet-800')"
              :class="value == 'violet-800' ? 'color-active' : ''"
            >
              <span class="swatch bg-violet-800"></span>
              <span class="title">violet-800</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                violet-900
              "
              @click="selectSwatch('violet-900')"
              :class="value == 'violet-900' ? 'color-active' : ''"
            >
              <span class="swatch bg-violet-900"></span>
              <span class="title">violet-900</span>
            </div>
          </div>
          <!--  -->
          <div class="row">
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                purple-50
              "
              @click="selectSwatch('purple-50')"
              :class="value == 'purple-50' ? 'color-active' : ''"
            >
              <span class="swatch bg-purple-50"></span>
              <span class="title">purple-50</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                purple-100
              "
              @click="selectSwatch('purple-100')"
              :class="value == 'purple-100' ? 'color-active' : ''"
            >
              <span class="swatch bg-purple-100"></span>
              <span class="title">purple-100</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                purple-200
              "
              @click="selectSwatch('purple-200')"
              :class="value == 'purple-200' ? 'color-active' : ''"
            >
              <span class="swatch bg-purple-200"></span>
              <span class="title">purple-200</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                purple-300
              "
              @click="selectSwatch('purple-300')"
              :class="value == 'purple-300' ? 'color-active' : ''"
            >
              <span class="swatch bg-purple-300"></span>
              <span class="title">purple-300</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                purple-400
              "
              @click="selectSwatch('purple-400')"
              :class="value == 'purple-400' ? 'color-active' : ''"
            >
              <span class="swatch bg-purple-400"></span>
              <span class="title">purple-400</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                purple-500
              "
              @click="selectSwatch('purple-500')"
              :class="value == 'purple-500' ? 'color-active' : ''"
            >
              <span class="swatch bg-purple-500"></span>
              <span class="title">purple-500</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                purple-600
              "
              @click="selectSwatch('purple-600')"
              :class="value == 'purple-600' ? 'color-active' : ''"
            >
              <span class="swatch bg-purple-600"></span>
              <span class="title">purple-600</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                purple-700
              "
              @click="selectSwatch('purple-700')"
              :class="value == 'purple-700' ? 'color-active' : ''"
            >
              <span class="swatch bg-purple-700"></span>
              <span class="title">purple-700</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                purple-800
              "
              @click="selectSwatch('purple-800')"
              :class="value == 'purple-800' ? 'color-active' : ''"
            >
              <span class="swatch bg-purple-800"></span>
              <span class="title">purple-800</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                purple-900
              "
              @click="selectSwatch('purple-900')"
              :class="value == 'purple-900' ? 'color-active' : ''"
            >
              <span class="swatch bg-purple-900"></span>
              <span class="title">purple-900</span>
            </div>
          </div>

          <!--  -->

          <div class="row">
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                fuchsia-50
              "
              @click="selectSwatch('fuchsia-50')"
              :class="value == 'fuchsia-50' ? 'color-active' : ''"
            >
              <span class="swatch bg-fuchsia-50"></span>
              <span class="title">fuchsia-50</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                fuchsia-100
              "
              @click="selectSwatch('fuchsia-100')"
              :class="value == 'fuchsia-100' ? 'color-active' : ''"
            >
              <span class="swatch bg-fuchsia-100"></span>
              <span class="title">fuchsia-100</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                fuchsia-200
              "
              @click="selectSwatch('fuchsia-200')"
              :class="value == 'fuchsia-200' ? 'color-active' : ''"
            >
              <span class="swatch bg-fuchsia-200"></span>
              <span class="title">fuchsia-200</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                fuchsia-300
              "
              @click="selectSwatch('fuchsia-300')"
              :class="value == 'fuchsia-300' ? 'color-active' : ''"
            >
              <span class="swatch bg-fuchsia-300"></span>
              <span class="title">fuchsia-300</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                fuchsia-400
              "
              @click="selectSwatch('fuchsia-400')"
              :class="value == 'fuchsia-400' ? 'color-active' : ''"
            >
              <span class="swatch bg-fuchsia-400"></span>
              <span class="title">fuchsia-400</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                fuchsia-500
              "
              @click="selectSwatch('fuchsia-500')"
              :class="value == 'fuchsia-500' ? 'color-active' : ''"
            >
              <span class="swatch bg-fuchsia-500"></span>
              <span class="title">fuchsia-500</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                fuchsia-600
              "
              @click="selectSwatch('fuchsia-600')"
              :class="value == 'fuchsia-600' ? 'color-active' : ''"
            >
              <span class="swatch bg-fuchsia-600"></span>
              <span class="title">fuchsia-600</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                fuchsia-700
              "
              @click="selectSwatch('fuchsia-700')"
              :class="value == 'fuchsia-700' ? 'color-active' : ''"
            >
              <span class="swatch bg-fuchsia-700"></span>
              <span class="title">fuchsia-700</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                fuchsia-800
              "
              @click="selectSwatch('fuchsia-800')"
              :class="value == 'fuchsia-800' ? 'color-active' : ''"
            >
              <span class="swatch bg-fuchsia-800"></span>
              <span class="title">fuchsia-800</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                fuchsia-900
              "
              @click="selectSwatch('fuchsia-900')"
              :class="value == 'fuchsia-900' ? 'color-active' : ''"
            >
              <span class="swatch bg-fuchsia-900"></span>
              <span class="title">fuchsia-900</span>
            </div>
          </div>
          <!--  -->
          <div class="row">
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                pink-50
              "
              @click="selectSwatch('pink-50')"
              :class="value == 'pink-50' ? 'color-active' : ''"
            >
              <span class="swatch bg-pink-50"></span>
              <span class="title">pink-50</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                pink-100
              "
              @click="selectSwatch('pink-100')"
              :class="value == 'pink-100' ? 'color-active' : ''"
            >
              <span class="swatch bg-pink-100"></span>
              <span class="title">pink-100</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                pink-200
              "
              @click="selectSwatch('pink-200')"
              :class="value == 'pink-200' ? 'color-active' : ''"
            >
              <span class="swatch bg-pink-200"></span>
              <span class="title">pink-200</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                pink-300
              "
              @click="selectSwatch('pink-300')"
              :class="value == 'pink-300' ? 'color-active' : ''"
            >
              <span class="swatch bg-pink-300"></span>
              <span class="title">pink-300</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                pink-400
              "
              @click="selectSwatch('pink-400')"
              :class="value == 'pink-400' ? 'color-active' : ''"
            >
              <span class="swatch bg-pink-400"></span>
              <span class="title">pink-400</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                pink-500
              "
              @click="selectSwatch('pink-500')"
              :class="value == 'pink-500' ? 'color-active' : ''"
            >
              <span class="swatch bg-pink-500"></span>
              <span class="title">pink-500</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                pink-600
              "
              @click="selectSwatch('pink-600')"
              :class="value == 'pink-600' ? 'color-active' : ''"
            >
              <span class="swatch bg-pink-600"></span>
              <span class="title">pink-600</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                pink-700
              "
              @click="selectSwatch('pink-700')"
              :class="value == 'pink-700' ? 'color-active' : ''"
            >
              <span class="swatch bg-pink-700"></span>
              <span class="title">pink-700</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                pink-800
              "
              @click="selectSwatch('pink-800')"
              :class="value == 'pink-800' ? 'color-active' : ''"
            >
              <span class="swatch bg-pink-800"></span>
              <span class="title">pink-800</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                pink-900
              "
              @click="selectSwatch('pink-900')"
              :class="value == 'pink-900' ? 'color-active' : ''"
            >
              <span class="swatch bg-pink-900"></span>
              <span class="title">pink-900</span>
            </div>
          </div>
          <!--  -->
          <div class="row">
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                rose-50
              "
              @click="selectSwatch('rose-50')"
              :class="value == 'rose-50' ? 'color-active' : ''"
            >
              <span class="swatch bg-rose-50"></span>
              <span class="title">rose-50</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                rose-100
              "
              @click="selectSwatch('rose-100')"
              :class="value == 'rose-100' ? 'color-active' : ''"
            >
              <span class="swatch bg-rose-100"></span>
              <span class="title">rose-100</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                rose-200
              "
              @click="selectSwatch('rose-200')"
              :class="value == 'rose-200' ? 'color-active' : ''"
            >
              <span class="swatch bg-rose-200"></span>
              <span class="title">rose-200</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                rose-300
              "
              @click="selectSwatch('rose-300')"
              :class="value == 'rose-300' ? 'color-active' : ''"
            >
              <span class="swatch bg-rose-300"></span>
              <span class="title">rose-300</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                rose-400
              "
              @click="selectSwatch('rose-400')"
              :class="value == 'rose-400' ? 'color-active' : ''"
            >
              <span class="swatch bg-rose-400"></span>
              <span class="title">rose-400</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                rose-500
              "
              @click="selectSwatch('rose-500')"
              :class="value == 'rose-500' ? 'color-active' : ''"
            >
              <span class="swatch bg-rose-500"></span>
              <span class="title">rose-500</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                rose-600
              "
              @click="selectSwatch('rose-600')"
              :class="value == 'rose-600' ? 'color-active' : ''"
            >
              <span class="swatch bg-rose-600"></span>
              <span class="title">rose-600</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                rose-700
              "
              @click="selectSwatch('rose-700')"
              :class="value == 'rose-700' ? 'color-active' : ''"
            >
              <span class="swatch bg-rose-700"></span>
              <span class="title">rose-700</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                rose-800
              "
              @click="selectSwatch('rose-800')"
              :class="value == 'rose-800' ? 'color-active' : ''"
            >
              <span class="swatch bg-rose-800"></span>
              <span class="title">rose-800</span>
            </div>
            <div
              class="
                col-auto
                text-center
                mb-4
                swatch-wrapper
                cursor-pointer
                rose-900
              "
              @click="selectSwatch('rose-900')"
              :class="value == 'rose-900' ? 'color-active' : ''"
            >
              <span class="swatch bg-rose-900"></span>
              <span class="title">rose-900</span>
            </div>
          </div>
          <!--  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      showSelector: false,
    };
  },
  methods: {
    selectSwatch(swatch) {
      this.$emit("input", swatch);
      this.showSelector = false;
    },
  },
};
</script>

<style scoped>
.color-select-window {
  position: absolute;
  z-index: 9999;
  height: 500px;
  width: 550px;
  overflow: scroll;
  top: 2rem;
  left: 8rem;
  background: white;
  border: dotted 2px blueviolet;
  padding: 0.5rem;
  box-shadow: 0 10px 14px rgba(55, 67, 67, 0.16);
}
.color-active {
  border: 2px blueviolet dotted;
}
.swatch-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.swatch {
  height: 1rem;
  width: 1rem;
}

.btn-swatch {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  line-height: 1rem;
  margin-right: 0.5rem;
}

.swatch-wrapper .title {
  display: none;
  font-size: smaller;
}

.bg-black {
  background-color: rgb(0, 0, 0);
}

.bg-white {
  background-color: rgb(255, 255, 255);
}

.bg-slate-50 {
  background-color: rgb(248, 250, 252);
}

.bg-slate-100 {
  background-color: rgb(241, 245, 249);
}

.bg-slate-200 {
  background-color: rgb(226, 232, 240);
}

.bg-slate-300 {
  background-color: rgb(203, 213, 225);
}

.bg-slate-400 {
  background-color: rgb(148, 163, 184);
}

.bg-slate-500 {
  background-color: rgb(100, 116, 139);
}

.bg-slate-600 {
  background-color: rgb(71, 85, 105);
}

.bg-slate-700 {
  background-color: rgb(51, 65, 85);
}

.bg-slate-800 {
  background-color: rgb(30, 41, 59);
}

.bg-slate-900 {
  background-color: rgb(15, 23, 42);
}

.bg-gray-50 {
  background-color: rgb(249, 250, 251);
}

.bg-gray-100 {
  background-color: rgb(243, 244, 246);
}

.bg-gray-200 {
  background-color: rgb(229, 231, 235);
}

.bg-gray-300 {
  background-color: rgb(209, 213, 219);
}

.bg-gray-400 {
  background-color: rgb(156, 163, 175);
}

.bg-gray-500 {
  background-color: rgb(107, 114, 128);
}

.bg-gray-600 {
  background-color: rgb(75, 85, 99);
}

.bg-gray-700 {
  background-color: rgb(55, 65, 81);
}

.bg-gray-800 {
  background-color: rgb(31, 41, 55);
}

.bg-gray-900 {
  background-color: rgb(17, 24, 39);
}

.bg-zinc-50 {
  background-color: rgb(250, 250, 250);
}

.bg-zinc-100 {
  background-color: rgb(244, 244, 245);
}

.bg-zinc-200 {
  background-color: rgb(228, 228, 231);
}

.bg-zinc-300 {
  background-color: rgb(212, 212, 216);
}

.bg-zinc-400 {
  background-color: rgb(161, 161, 170);
}

.bg-zinc-500 {
  background-color: rgb(113, 113, 122);
}

.bg-zinc-600 {
  background-color: rgb(82, 82, 91);
}

.bg-zinc-700 {
  background-color: rgb(63, 63, 70);
}

.bg-zinc-800 {
  background-color: rgb(39, 39, 42);
}

.bg-zinc-900 {
  background-color: rgb(24, 24, 27);
}

.bg-neutral-50 {
  background-color: rgb(250, 250, 250);
}

.bg-neutral-100 {
  background-color: rgb(245, 245, 245);
}

.bg-neutral-200 {
  background-color: rgb(229, 229, 229);
}

.bg-neutral-300 {
  background-color: rgb(212, 212, 212);
}

.bg-neutral-400 {
  background-color: rgb(163, 163, 163);
}

.bg-neutral-500 {
  background-color: rgb(115, 115, 115);
}

.bg-neutral-600 {
  background-color: rgb(82, 82, 82);
}

.bg-neutral-700 {
  background-color: rgb(64, 64, 64);
}

.bg-neutral-800 {
  background-color: rgb(38, 38, 38);
}

.bg-neutral-900 {
  background-color: rgb(23, 23, 23);
}

.bg-stone-50 {
  background-color: rgb(250, 250, 249);
}

.bg-stone-100 {
  background-color: rgb(245, 245, 244);
}

.bg-stone-200 {
  background-color: rgb(231, 229, 228);
}

.bg-stone-300 {
  background-color: rgb(214, 211, 209);
}

.bg-stone-400 {
  background-color: rgb(168, 162, 158);
}

.bg-stone-500 {
  background-color: rgb(120, 113, 108);
}

.bg-stone-600 {
  background-color: rgb(87, 83, 78);
}

.bg-stone-700 {
  background-color: rgb(68, 64, 60);
}

.bg-stone-800 {
  background-color: rgb(41, 37, 36);
}

.bg-stone-900 {
  background-color: rgb(28, 25, 23);
}

.bg-red-50 {
  background-color: rgb(254, 242, 242);
}

.bg-red-100 {
  background-color: rgb(254, 226, 226);
}

.bg-red-200 {
  background-color: rgb(254, 202, 202);
}

.bg-red-300 {
  background-color: rgb(252, 165, 165);
}

.bg-red-400 {
  background-color: rgb(248, 113, 113);
}

.bg-red-500 {
  background-color: rgb(239, 68, 68);
}

.bg-red-600 {
  background-color: rgb(220, 38, 38);
}

.bg-red-700 {
  background-color: rgb(185, 28, 28);
}

.bg-red-800 {
  background-color: rgb(153, 27, 27);
}

.bg-red-900 {
  background-color: rgb(127, 29, 29);
}

.bg-orange-50 {
  background-color: rgb(255, 247, 237);
}

.bg-orange-100 {
  background-color: rgb(255, 237, 213);
}

.bg-orange-200 {
  background-color: rgb(254, 215, 170);
}

.bg-orange-300 {
  background-color: rgb(253, 186, 116);
}

.bg-orange-400 {
  background-color: rgb(251, 146, 60);
}

.bg-orange-500 {
  background-color: rgb(249, 115, 22);
}

.bg-orange-600 {
  background-color: rgb(234, 88, 12);
}

.bg-orange-700 {
  background-color: rgb(194, 65, 12);
}

.bg-orange-800 {
  background-color: rgb(154, 52, 18);
}

.bg-orange-900 {
  background-color: rgb(124, 45, 18);
}

.bg-amber-50 {
  background-color: rgb(255, 251, 235);
}

.bg-amber-100 {
  background-color: rgb(254, 243, 199);
}

.bg-amber-200 {
  background-color: rgb(253, 230, 138);
}

.bg-amber-300 {
  background-color: rgb(252, 211, 77);
}

.bg-amber-400 {
  background-color: rgb(251, 191, 36);
}

.bg-amber-500 {
  background-color: rgb(245, 158, 11);
}

.bg-amber-600 {
  background-color: rgb(217, 119, 6);
}

.bg-amber-700 {
  background-color: rgb(180, 83, 9);
}

.bg-amber-800 {
  background-color: rgb(146, 64, 14);
}

.bg-amber-900 {
  background-color: rgb(120, 53, 15);
}

.bg-yellow-50 {
  background-color: rgb(254, 252, 232);
}

.bg-yellow-100 {
  background-color: rgb(254, 249, 195);
}

.bg-yellow-200 {
  background-color: rgb(254, 240, 138);
}

.bg-yellow-300 {
  background-color: rgb(253, 224, 71);
}

.bg-yellow-400 {
  background-color: rgb(250, 204, 21);
}

.bg-yellow-500 {
  background-color: rgb(234, 179, 8);
}

.bg-yellow-600 {
  background-color: rgb(202, 138, 4);
}

.bg-yellow-700 {
  background-color: rgb(161, 98, 7);
}

.bg-yellow-800 {
  background-color: rgb(133, 77, 14);
}

.bg-yellow-900 {
  background-color: rgb(113, 63, 18);
}

.bg-lime-50 {
  background-color: rgb(247, 254, 231);
}

.bg-lime-100 {
  background-color: rgb(236, 252, 203);
}

.bg-lime-200 {
  background-color: rgb(217, 249, 157);
}

.bg-lime-300 {
  background-color: rgb(190, 242, 100);
}

.bg-lime-400 {
  background-color: rgb(163, 230, 53);
}

.bg-lime-500 {
  background-color: rgb(132, 204, 22);
}

.bg-lime-600 {
  background-color: rgb(101, 163, 13);
}

.bg-lime-700 {
  background-color: rgb(77, 124, 15);
}

.bg-lime-800 {
  background-color: rgb(63, 98, 18);
}

.bg-lime-900 {
  background-color: rgb(54, 83, 20);
}

.bg-green-50 {
  background-color: rgb(240, 253, 244);
}

.bg-green-100 {
  background-color: rgb(220, 252, 231);
}

.bg-green-200 {
  background-color: rgb(187, 247, 208);
}

.bg-green-300 {
  background-color: rgb(134, 239, 172);
}

.bg-green-400 {
  background-color: rgb(74, 222, 128);
}

.bg-green-500 {
  background-color: rgb(34, 197, 94);
}

.bg-green-600 {
  background-color: rgb(22, 163, 74);
}

.bg-green-700 {
  background-color: rgb(21, 128, 61);
}

.bg-green-800 {
  background-color: rgb(22, 101, 52);
}

.bg-green-900 {
  background-color: rgb(20, 83, 45);
}

.bg-emerald-50 {
  background-color: rgb(236, 253, 245);
}

.bg-emerald-100 {
  background-color: rgb(209, 250, 229);
}

.bg-emerald-200 {
  background-color: rgb(167, 243, 208);
}

.bg-emerald-300 {
  background-color: rgb(110, 231, 183);
}

.bg-emerald-400 {
  background-color: rgb(52, 211, 153);
}

.bg-emerald-500 {
  background-color: rgb(16, 185, 129);
}

.bg-emerald-600 {
  background-color: rgb(5, 150, 105);
}

.bg-emerald-700 {
  background-color: rgb(4, 120, 87);
}

.bg-emerald-800 {
  background-color: rgb(6, 95, 70);
}

.bg-emerald-900 {
  background-color: rgb(6, 78, 59);
}

.bg-teal-50 {
  background-color: rgb(240, 253, 250);
}

.bg-teal-100 {
  background-color: rgb(204, 251, 241);
}

.bg-teal-200 {
  background-color: rgb(153, 246, 228);
}

.bg-teal-300 {
  background-color: rgb(94, 234, 212);
}

.bg-teal-400 {
  background-color: rgb(45, 212, 191);
}

.bg-teal-500 {
  background-color: rgb(20, 184, 166);
}

.bg-teal-600 {
  background-color: rgb(13, 148, 136);
}

.bg-teal-700 {
  background-color: rgb(15, 118, 110);
}

.bg-teal-800 {
  background-color: rgb(17, 94, 89);
}

.bg-teal-900 {
  background-color: rgb(19, 78, 74);
}

.bg-cyan-50 {
  background-color: rgb(236, 254, 255);
}

.bg-cyan-100 {
  background-color: rgb(207, 250, 254);
}

.bg-cyan-200 {
  background-color: rgb(165, 243, 252);
}

.bg-cyan-300 {
  background-color: rgb(103, 232, 249);
}

.bg-cyan-400 {
  background-color: rgb(34, 211, 238);
}

.bg-cyan-500 {
  background-color: rgb(6, 182, 212);
}

.bg-cyan-600 {
  background-color: rgb(8, 145, 178);
}

.bg-cyan-700 {
  background-color: rgb(14, 116, 144);
}

.bg-cyan-800 {
  background-color: rgb(21, 94, 117);
}

.bg-cyan-900 {
  background-color: rgb(22, 78, 99);
}

.bg-sky-50 {
  background-color: rgb(240, 249, 255);
}

.bg-sky-100 {
  background-color: rgb(224, 242, 254);
}

.bg-sky-200 {
  background-color: rgb(186, 230, 253);
}

.bg-sky-300 {
  background-color: rgb(125, 211, 252);
}

.bg-sky-400 {
  background-color: rgb(56, 189, 248);
}

.bg-sky-500 {
  background-color: rgb(14, 165, 233);
}

.bg-sky-600 {
  background-color: rgb(2, 132, 199);
}

.bg-sky-700 {
  background-color: rgb(3, 105, 161);
}

.bg-sky-800 {
  background-color: rgb(7, 89, 133);
}

.bg-sky-900 {
  background-color: rgb(12, 74, 110);
}

.bg-blue-50 {
  background-color: rgb(239, 246, 255);
}

.bg-blue-100 {
  background-color: rgb(219, 234, 254);
}

.bg-blue-200 {
  background-color: rgb(191, 219, 254);
}

.bg-blue-300 {
  background-color: rgb(147, 197, 253);
}

.bg-blue-400 {
  background-color: rgb(96, 165, 250);
}

.bg-blue-500 {
  background-color: rgb(59, 130, 246);
}

.bg-blue-600 {
  background-color: rgb(37, 99, 235);
}

.bg-blue-700 {
  background-color: rgb(29, 78, 216);
}

.bg-blue-800 {
  background-color: rgb(30, 64, 175);
}

.bg-blue-900 {
  background-color: rgb(30, 58, 138);
}

.bg-indigo-50 {
  background-color: rgb(238, 242, 255);
}

.bg-indigo-100 {
  background-color: rgb(224, 231, 255);
}

.bg-indigo-200 {
  background-color: rgb(199, 210, 254);
}

.bg-indigo-300 {
  background-color: rgb(165, 180, 252);
}

.bg-indigo-400 {
  background-color: rgb(129, 140, 248);
}

.bg-indigo-500 {
  background-color: rgb(99, 102, 241);
}

.bg-indigo-600 {
  background-color: rgb(79, 70, 229);
}

.bg-indigo-700 {
  background-color: rgb(67, 56, 202);
}

.bg-indigo-800 {
  background-color: rgb(55, 48, 163);
}

.bg-indigo-900 {
  background-color: rgb(49, 46, 129);
}

.bg-violet-50 {
  background-color: rgb(245, 243, 255);
}

.bg-violet-100 {
  background-color: rgb(237, 233, 254);
}

.bg-violet-200 {
  background-color: rgb(221, 214, 254);
}

.bg-violet-300 {
  background-color: rgb(196, 181, 253);
}

.bg-violet-400 {
  background-color: rgb(167, 139, 250);
}

.bg-violet-500 {
  background-color: rgb(139, 92, 246);
}

.bg-violet-600 {
  background-color: rgb(124, 58, 237);
}

.bg-violet-700 {
  background-color: rgb(109, 40, 217);
}

.bg-violet-800 {
  background-color: rgb(91, 33, 182);
}

.bg-violet-900 {
  background-color: rgb(76, 29, 149);
}

.bg-purple-50 {
  background-color: rgb(250, 245, 255);
}

.bg-purple-100 {
  background-color: rgb(243, 232, 255);
}

.bg-purple-200 {
  background-color: rgb(233, 213, 255);
}

.bg-purple-300 {
  background-color: rgb(216, 180, 254);
}

.bg-purple-400 {
  background-color: rgb(192, 132, 252);
}

.bg-purple-500 {
  background-color: rgb(168, 85, 247);
}

.bg-purple-600 {
  background-color: rgb(147, 51, 234);
}

.bg-purple-700 {
  background-color: rgb(126, 34, 206);
}

.bg-purple-800 {
  background-color: rgb(107, 33, 168);
}

.bg-purple-900 {
  background-color: rgb(88, 28, 135);
}

.bg-fuchsia-50 {
  background-color: rgb(253, 244, 255);
}

.bg-fuchsia-100 {
  background-color: rgb(250, 232, 255);
}

.bg-fuchsia-200 {
  background-color: rgb(245, 208, 254);
}

.bg-fuchsia-300 {
  background-color: rgb(240, 171, 252);
}

.bg-fuchsia-400 {
  background-color: rgb(232, 121, 249);
}

.bg-fuchsia-500 {
  background-color: rgb(217, 70, 239);
}

.bg-fuchsia-600 {
  background-color: rgb(192, 38, 211);
}

.bg-fuchsia-700 {
  background-color: rgb(162, 28, 175);
}

.bg-fuchsia-800 {
  background-color: rgb(134, 25, 143);
}

.bg-fuchsia-900 {
  background-color: rgb(112, 26, 117);
}

.bg-pink-50 {
  background-color: rgb(253, 242, 248);
}

.bg-pink-100 {
  background-color: rgb(252, 231, 243);
}

.bg-pink-200 {
  background-color: rgb(251, 207, 232);
}

.bg-pink-300 {
  background-color: rgb(249, 168, 212);
}

.bg-pink-400 {
  background-color: rgb(244, 114, 182);
}

.bg-pink-500 {
  background-color: rgb(236, 72, 153);
}

.bg-pink-600 {
  background-color: rgb(219, 39, 119);
}

.bg-pink-700 {
  background-color: rgb(190, 24, 93);
}

.bg-pink-800 {
  background-color: rgb(157, 23, 77);
}

.bg-pink-900 {
  background-color: rgb(131, 24, 67);
}

.bg-rose-50 {
  background-color: rgb(255, 241, 242);
}

.bg-rose-100 {
  background-color: rgb(255, 228, 230);
}

.bg-rose-200 {
  background-color: rgb(254, 205, 211);
}

.bg-rose-300 {
  background-color: rgb(253, 164, 175);
}

.bg-rose-400 {
  background-color: rgb(251, 113, 133);
}

.bg-rose-500 {
  background-color: rgb(244, 63, 94);
}

.bg-rose-600 {
  background-color: rgb(225, 29, 72);
}

.bg-rose-700 {
  background-color: rgb(190, 18, 60);
}

.bg-rose-800 {
  background-color: rgb(159, 18, 57);
}

.bg-rose-900 {
  background-color: rgb(136, 19, 55);
}
</style>
